<template>
  <!-- Page Content  -->
  <b-container fluid>
    <!-- BEGIN:Modal Create MoU Mitra -->
    <b-modal
      id="modal-create"
      v-model="isModalMitra"
      size="lg"
      centered
      title="Tambah Data MoU Mitra"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group>
          <input-datalists
            id="idMitra"
            class="w-full text-sm"
            placeholder="Pilih Kode Mitra"
            v-model="form.idMitra"
            label="dataKodeMitra"
            required
            @input="inputKodeMitra"
            :options="
              (listDataMitra &&
                listDataMitra.map(data => {
                  return {
                    dataKodeMitra: data.kodeMitra + ` | ` + data.namaMitra,
                    idMitra: parseInt(data.idMitra)
                  }
                })) ||
                []
            "
            :reduce="listDataMitra => listDataMitra.idMitra"
            :clearable="false"
          >
            <template #header>
              <label class="block"
                >Kode Mitra: <span class="text-danger">*</span></label
              >
            </template>
          </input-datalists>
        </b-form-group>
        <b-form-group>
          <label for="nomorPksMitra">Nomor MoU Mitra:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="nomorPksMitra"
            v-model="form.nomorPksMitra"
            required
          />
        </b-form-group>
        <b-form-group>
          <label for="penandatanganMitra">Pihak Mitra (Penandatangan):</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="penandatanganMitra"
            v-model="form.penandatanganMitra"
            required
          />
        </b-form-group>
        <b-form-group>
          <label for="jabatanMitra">Jabatan Mitra:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="jabatanMitra"
            v-model="form.jabatanMitra"
            required
          />
        </b-form-group>
        <b-form-group label="Email Mitra:" label-for="emailMitra">
          <input-primary
            type="email"
            :regex="/\s/gi"
            regexMessage="Tidak dapat memuat spasi"
            v-model="form.emailMitra"
            @input="inputEmailMitra()"
            lowerCase
            id="emailMitra"
            :isError="!isValidEmailMitra"
            isDetail
          />
          <collapse-transition>
            <div
              class="px-2 text-danger mt-2"
              v-show="isError.emailMitra.validEmailMitra"
            >
              Email harus mengandung karakter @
            </div>
          </collapse-transition>
        </b-form-group>
        <b-form-group label="Penghubung Mitra:" label-for="idPenghubungMitra">
          <input-datalists
            id="idPenghubungMitra"
            class="w-full text-sm"
            v-b-tooltip="
              form.idMitra == null ? 'Pilih Kode Mitra lebih dulu' : ''
            "
            :placeholder="
              form.idMitra == null
                ? 'Pilih Kode Mitra lebih dulu'
                : 'Pilih Penghubung Mitra'
            "
            v-model="form.idPenghubungMitra"
            label="namaPenghubungMitra"
            required
            :options="listDataPenghubungMitra || []"
            :reduce="
              listDataPenghubungMitra =>
                listDataPenghubungMitra.idPenghubungMitra
            "
            :disabled="form.idMitra == null"
          >
            <template v-slot:option="option">
              <div class="flex justify-between p-1">
                {{
                  option.namaPenghubungMitra
                    ? option.namaPenghubungMitra
                    : 'Data tidak ditemukan'
                }}
              </div>
            </template>
          </input-datalists>
        </b-form-group>
        <b-form-group label="Telepon Mitra:" label-for="telpMitra">
          <input-primary
            id="telpMitra"
            v-model="form.telpMitra"
            phone
            isDetail
          />
        </b-form-group>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalMitra">Batal</b-btn>
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="addMitra"
              :disabled="!isValidFormMitra"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Create MoU Mitra -->
    <!-- BEGIN:Modal Edit MoU Mitra -->
    <b-modal
      id="modal-edit-mitra"
      v-model="isModalEditMitra"
      size="lg"
      centered
      title="Ubah Data MoU Mitra"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group>
          <label class="block"
            >Kode Mitra: <span class="text-danger">*</span></label
          >
          <input-datalists
            id="idMitra"
            class="w-full text-sm"
            placeholder="Pilih Kode Mitra"
            v-model="modal.idMitra"
            label="kodeMitra"
            required
            :options="listDataMitra || []"
            :reduce="listDataMitra => listDataMitra.idMitra"
            disabled
            :clearable="false"
          />
        </b-form-group>
        <b-form-group>
          <label for="nomorPksMitra">Nomor MoU Mitra:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="nomorPksMitra"
            v-model="modal.nomorPksMitra"
            required
            max-length="30"
          />
        </b-form-group>
        <b-form-group>
          <label for="penandatanganMitra">Pihak Mitra (Penandatangan):</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="penandatanganMitra"
            v-model="modal.penandatanganMitra"
            required
          />
        </b-form-group>
        <b-form-group>
          <label for="jabatanMitra">Jabatan Mitra:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="jabatanMitra"
            v-model="modal.jabatanMitra"
            required
          />
        </b-form-group>
        <b-form-group label="Email Mitra:" label-for="emailMitra">
          <input-primary
            type="email"
            :regex="/\s/gi"
            regexMessage="Tidak dapat memuat spasi"
            v-model="modal.emailMitra"
            @input="inputEmailMitra()"
            lowerCase
            id="emailMitra"
            :isError="!isValidEmailMitra"
            isDetail
          />
          <collapse-transition>
            <div
              class="px-2 text-danger mt-2"
              v-show="isError.emailMitra.validEmailMitra"
            >
              Email harus mengandung karakter @
            </div>
          </collapse-transition>
        </b-form-group>
        <b-form-group label="Penghubung Mitra:" label-for="idPenghubungMitra">
          <input-datalists
            id="idPenghubungMitra"
            class="w-full text-sm"
            placeholder="Pilih Penghubung Mitra"
            v-model="modal.idPenghubungMitra"
            label="namaPenghubungMitra"
            required
            :options="listDataPenghubungMitra || []"
            :reduce="
              listDataPenghubungMitra =>
                listDataPenghubungMitra.idPenghubungMitra
            "
          />
        </b-form-group>
        <b-form-group label="Telepon Mitra:" label-for="telpMitra">
          <input-primary
            id="telpMitra"
            v-model="modal.telpMitra"
            phone
            isDetail
          />
        </b-form-group>
        <b-row class="mt-5">
          <b-col>
            <b-btn class="btn-block" @click="closeModalEditMitra">Batal</b-btn>
          </b-col>
          <b-col>
            <b-btn
              class="btn-block"
              variant="success"
              @click="addMitraModal"
              :disabled="!isValidModalMitra"
              >Simpan</b-btn
            >
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Edit MoU Mitra -->
    <!-- BEGIN:Modal Detail MoU Mitra -->
    <b-modal
      id="modal-detail-mitra"
      v-model="isModalDetailMitra"
      size="lg"
      centered
      title="Detail Data MoU Mitra"
      title-class="text-white font-weight-bold"
      no-close-on-backdrop
      hide-footer
      header-bg-variant="primary"
      header-text-variant="white"
    >
      <b-form class="px-4">
        <b-form-group>
          <label for="idMitra">Kode Mitra:</label
          ><span class="text-danger ml-1">*</span>
          <input-datalists
            id="idMitra"
            class="w-full text-sm"
            placeholder="Pilih Kode Mitra"
            v-model="modal.idMitra"
            label="kodeMitra"
            required
            :options="listDataMitra || []"
            :reduce="listDataMitra => listDataMitra.idMitra"
            disabled
            :clearable="false"
          />
        </b-form-group>
        <b-form-group>
          <label for="nomorPksMitra">Nomor MoU Mitra:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="nomorPksMitra"
            v-model="modal.nomorPksMitra"
            required
            isDetail
          />
        </b-form-group>
        <b-form-group>
          <label for="penandatanganMitra">Pihak Mitra (Penandatangan):</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="penandatanganMitra"
            v-model="modal.penandatanganMitra"
            required
            isDetail
          />
        </b-form-group>
        <b-form-group>
          <label for="jabatanMitra">Jabatan Mitra:</label
          ><span class="text-danger ml-1">*</span>
          <input-primary
            id="jabatanMitra"
            v-model="modal.jabatanMitra"
            required
            isDetail
          />
        </b-form-group>
        <b-form-group label="Email Mitra:" label-for="emailMitra">
          <input-primary
            type="email"
            v-model="modal.emailMitra"
            lowerCase
            required
            id="emailMitra"
            isDetail
          />
        </b-form-group>
        <b-form-group label="Penghubung Mitra:" label-for="idPenghubungMitra">
          <input-datalists
            id="idPenghubungMitra"
            class="w-full text-sm"
            placeholder="Pilih Penghubung Mitra"
            v-model="modal.idPenghubungMitra"
            label="namaPenghubungMitra"
            required
            :options="listDataPenghubungMitra || []"
            :reduce="
              listDataPenghubungMitra =>
                listDataPenghubungMitra.idPenghubungMitra
            "
            :clearable="false"
            disabled
          />
        </b-form-group>
        <b-form-group label="Telepon Mitra:" label-for="telpMitra">
          <input-primary
            id="telpMitra"
            v-model="modal.telpMitra"
            required
            phone
            isDetail
          />
        </b-form-group>
        <b-row class="mt-5">
          <b-btn class="btn-block" @click="closeModalDetailMitra"
            >Kembali</b-btn
          >
        </b-row>
      </b-form>
    </b-modal>
    <!-- ./END:Modal Detail MoU Mitra -->
    <b-row>
      <!-- BEGIN:Table -->
      <b-col md="12">
        <iq-card
          bodyClass="relative-background"
          class="iq-card-block iq-card-stretch iq-card-height"
        >
          <template v-slot:headerTitle>
            <h4 class="card-title">Tambah Data MoU</h4>
          </template>
          <template v-slot:body>
            <div class="d-inline-block float-right">
              <router-link
                class="btn btn-secondary dark-btn-primary"
                :to="{ name: 'kerjasama.jenisDokumen' }"
                role="button"
                ><i class="ri-arrow-left-line"></i>Kembali</router-link
              >
            </div>
            <div class="align-items-center mt-lg-5">
              <!-- <b-form> -->
              <div>
                <b-form-group>
                  <input-datalists
                    id="idJenisPks"
                    ref="refJenisPks"
                    class="w-full text-sm"
                    placeholder="Pilih Kode Jenis MoU"
                    v-model="form.idJenisPks"
                    label="namaPks"
                    required
                    :options="listDataJenisPks || []"
                    :reduce="listDataJenisPks => listDataJenisPks.idJenisPks"
                  >
                    <template #header>
                      <label class="block"
                        >Kode Jenis MoU:
                        <span class="text-danger">*</span></label
                      >
                    </template>
                    <template v-slot:option="option">
                      <div class="flex justify-between p-1">
                        {{ option.kodeJenisPks }} | {{ option.namaPks }}
                      </div>
                    </template>
                  </input-datalists>
                </b-form-group>
                <b-form-group>
                  <label for="namaPks">Judul MoU:</label
                  ><span class="text-danger ml-1">*</span>
                  <input-primary
                    id="namaPks"
                    v-model="form.namaPks"
                    required
                    type="textarea"
                    rows="3"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="idUnitKs">Unit Penyelenggara:</label
                  ><span class="text-danger ml-1">*</span>
                  <div class="text-secondary">
                    * Jika tidak ada unit penyelenggara, mohon isikan 000|Umum
                  </div>
                  <input-datalists
                    id="idUnitKs"
                    ref="refUnitKs"
                    class="w-full text-sm"
                    placeholder="Pilih Unit Penyelenggara"
                    v-model="form.idUnitKs"
                    @input="inputUnit"
                    label="dataUnitKs"
                    required
                    :options="
                      (listDataUnitPks &&
                        listDataUnitPks.map(data => {
                          return {
                            dataUnitKs: data.kodeUnit + ` | ` + data.namaUnit,
                            kodeUnitKs: data.kodeUnit,
                            idUnitKs: parseInt(data.idUnit)
                          }
                        })) ||
                        []
                    "
                    :reduce="listDataUnitPks => listDataUnitPks.idUnitKs"
                  />
                </b-form-group>
                <b-form-group>
                  <label for="idUnitPelaksana">Unit Pelaksana:</label>
                  <div class="text-secondary">
                    * Jika unit pelaksana sama dengan unit penyelenggara, maka
                    cukup dimasukkan pada unit penyelenggara saja
                  </div>
                  <input-datalists
                    multiple
                    :closeOnSelect="false"
                    id="idUnitPelaksana"
                    ref="refUnitPelaksana"
                    class="w-full text-sm"
                    v-b-tooltip="
                      !form.idUnitKs
                        ? 'Pilih Unit Penyelenggara lebih dulu'
                        : ''
                    "
                    :placeholder="
                      !form.idUnitKs
                        ? 'Pilih Unit Penyelenggara lebih dulu'
                        : 'Pilih Unit Pelaksana'
                    "
                    v-model="form.idUnitPelaksana"
                    @input="inputUnitPelaksana"
                    label="namaUnit"
                    :options="listDataUnitPks || []"
                    :reduce="listDataUnitPks => listDataUnitPks.idUnit"
                    :disabled="!form.idUnitKs"
                  >
                    <template v-slot:option="option">
                      <div class="flex justify-between p-1">
                        {{ option.kodeUnit }} | {{ option.namaUnit }}
                      </div>
                    </template>
                  </input-datalists>
                </b-form-group>
                <b-row class="mt-4">
                  <!-- Kategori MoU -->
                  <b-col>
                    <h4 class="mb-2">KATEGORI MoU</h4>
                    <b-form-group>
                      <input-datalists
                        id="idKategoriPks"
                        ref="refKategoriPks"
                        class="w-full text-sm"
                        placeholder="Pilih Kategori MoU"
                        v-model="form.idKategoriPks"
                        label="namaKategori"
                        required
                        @input="inputKodeKategori"
                        :options="listDataKategoriPks || []"
                        :reduce="
                          listDataKategoriPks => listDataKategoriPks.idKategori
                        "
                      >
                        <template #header>
                          <label class="block"
                            >Kategori MoU:
                            <span class="text-danger">*</span></label
                          >
                        </template>
                        <template v-slot:option="option">
                          <div class="flex justify-between p-1">
                            {{ option.kodeKategori }} |
                            {{ option.namaKategori }}
                          </div>
                        </template>
                      </input-datalists>
                    </b-form-group>
                    <b-form-group>
                      <input-datalists
                        id="idSubKategoriPks"
                        ref="refSubKategoriPks"
                        class="w-full text-sm"
                        :placeholder="
                          !form.idKategoriPks
                            ? 'Pilih Kategori MoU lebih dulu'
                            : 'Pilih Sub Kategori MoU'
                        "
                        v-model="form.idSubKategoriPks"
                        label="namaSubKategori"
                        required
                        :options="listDataSubKategoriPks || []"
                        :reduce="
                          listDataSubKategoriPks =>
                            listDataSubKategoriPks.idSubKategori
                        "
                        :disabled="!form.idKategoriPks"
                      >
                        <template #header>
                          <label class="block"
                            >Sub Kategori MoU:
                            <span class="text-danger">*</span></label
                          >
                        </template>
                        <template v-slot:option="option">
                          <div class="flex justify-between p-1">
                            {{
                              option.kodeSubKategori == null
                                ? `Data tidak ditemukan`
                                : option.kodeSubKategori +
                                  ` | ` +
                                  option.namaSubKategori
                            }}
                          </div>
                        </template>
                      </input-datalists>
                    </b-form-group>
                    <b-form-group>
                      <label for="ruangLingkup">Ruang Lingkup MoU:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="ruangLingkup"
                        v-model="form.ruangLingkup"
                        required
                        type="textarea"
                        rows="3"
                      />
                    </b-form-group>
                    <h6 class="mb-2">
                      Luaran:<span class="text-danger ml-1">*</span>
                    </h6>
                    <b-form-row>
                      <b-col lg="4">
                        <input-primary
                          id="volume"
                          v-model="form.volume"
                          required
                          type="number"
                          max-length="9"
                          placeholder="Volume"
                          isPrepend
                        />
                      </b-col>
                      <b-col>
                        <input-primary
                          id="satuanVol"
                          v-model="form.satuanVol"
                          required
                          type="text"
                          max-length="20"
                          placeholder="@"
                          isPrepend
                        />
                      </b-col>
                    </b-form-row>
                  </b-col>
                  <!-- Tanggal MoU -->
                  <b-col>
                    <h4 class="mb-2">TANGGAL MoU</h4>
                    <b-form-group>
                      <label for="tglPks">Tanggal MoU:</label
                      ><span class="text-danger ml-1">*</span>
                      <flat-pickr
                        id="tglPks"
                        ref="refTglPks"
                        class="form-control bg-white"
                        :config="basicConfig"
                        v-model="form.tglPks"
                        placeholder="Tanggal MoU"
                        @input="inputTanggalPks"
                        required
                      ></flat-pickr>
                    </b-form-group>
                    <div v-if="!form.tglPks">
                      <b-form-group>
                        <label>Tanggal Berlaku MoU:</label
                        ><span class="text-danger ml-1">*</span>
                        <input-primary
                          class="bg-light cursor-not-allowed"
                          placeholder="Masukkan Tanggal MoU lebih dulu"
                          disabled
                        />
                      </b-form-group>
                      <b-form-group>
                        <label>Tanggal Berakhir MoU:</label
                        ><span class="text-danger ml-1">*</span>
                        <input-primary
                          class="bg-light cursor-not-allowed"
                          placeholder="Masukkan Tanggal MoU dan Tanggal Berlaku MoU lebih dulu"
                          disabled
                        />
                      </b-form-group>
                    </div>
                    <div v-else>
                      <!-- Tanggal Berlaku MoU -->
                      <b-form-group>
                        <label for="berlakuPks">Tanggal Berlaku MoU:</label
                        ><span class="text-danger ml-1">*</span>
                        <flat-pickr
                          id="berlakuPks"
                          ref="refBerlakuPks"
                          class="form-control bg-white"
                          :config="basicConfig"
                          v-model="form.berlakuPks"
                          placeholder="Tanggal Berlaku MoU"
                          @input="inputTanggalBerlakuPks"
                          :isError="!isValidBerlakuPks"
                          required
                        ></flat-pickr>
                        <collapse-transition>
                          <div
                            class="text-danger mb-2"
                            v-show="isError.berlakuPks.validBerlakuPks"
                          >
                            Tanggal Berlaku MoU tidak bisa kurang dari Tanggal
                            MoU.
                          </div>
                        </collapse-transition>
                      </b-form-group>
                      <!-- Tanggal Berakhir MoU -->
                      <b-form-group
                        v-if="!form.berlakuPks || !isValidBerlakuPks"
                      >
                        <label>Tanggal Berakhir MoU:</label
                        ><span class="text-danger ml-1">*</span>
                        <input-primary
                          class="bg-light cursor-not-allowed"
                          placeholder="Masukkan Tanggal Berlaku MoU lebih dulu"
                          disabled
                        />
                      </b-form-group>
                      <b-form-group v-else>
                        <label for="berakhirPks">Tanggal Berakhir MoU:</label
                        ><span class="text-danger ml-1">*</span>
                        <flat-pickr
                          id="berakhirPks"
                          ref="refBerakhirPks"
                          class="form-control bg-white"
                          :config="basicConfig"
                          v-model="form.berakhirPks"
                          placeholder="Tanggal Berakhir MoU"
                          @input="inputTanggalBerakhirPks"
                          :isError="!isValidBerakhirPks"
                          required
                        ></flat-pickr>
                        <collapse-transition>
                          <div
                            class="text-danger"
                            v-show="
                              isError.berakhirPks.validBerakhirPksFromTanggalKs
                            "
                          >
                            Tanggal Berakhir MoU tidak bisa kurang dari Tanggal
                            MoU.
                          </div>
                        </collapse-transition>
                        <collapse-transition>
                          <div
                            class="text-danger mb-2"
                            v-show="
                              isError.berakhirPks.validBerakhirPksFromBerlakuKs
                            "
                          >
                            Tanggal Berakhir MoU tidak bisa kurang dari Tanggal
                            Berlaku MoU.
                          </div>
                        </collapse-transition>
                      </b-form-group>
                    </div>
                    <!-- <b-form-group>
                      <label for="jangkaWaktu">Jangka Waktu MoU:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="jangkaWaktu"
                        v-model="form.jangkaWaktu"
                        required
                      />
                    </b-form-group> -->
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <!-- MoU UT -->
                  <b-col>
                    <h4 class="mb-2">MoU UT</h4>
                    <b-form-group>
                      <label for="nomorPksUt">Nomor MoU UT:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="nomorPksUt"
                        v-model="form.nomorPksUt"
                        required
                        max-length="50"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="penandatanganUt"
                        >Pihak UT (Penandatangan):</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="penandatanganUt"
                        v-model="form.penandatanganUt"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="jabatanUt">Jabatan UT:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="jabatanUt"
                        v-model="form.jabatanUt"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="emailUt">Email UT:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        type="email"
                        :regex="/\s/gi"
                        regexMessage="Tidak dapat memuat spasi"
                        v-model="form.emailUt"
                        @input="inputEmail()"
                        lowerCase
                        required
                        id="emailUt"
                        :isError="!isValidEmail"
                      />
                      <collapse-transition>
                        <div
                          class="px-2 text-danger mt-2"
                          v-show="isError.emailUt.validEmail"
                        >
                          Email harus mengandung karakter @
                        </div>
                      </collapse-transition>
                    </b-form-group>
                    <b-form-group>
                      <label for="penghubungUt">Penghubung UT:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="penghubungUt"
                        v-model="form.penghubungUt"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="telpUt">Telepon UT:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="telpUt"
                        v-model="form.telpUt"
                        required
                        phone
                        max-length="15"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Nominal MoU -->
                  <b-col>
                    <h4 class="mb-2">NOMINAL MoU</h4>
                    <b-form-group>
                      <label for="nominalPks">Nominal MoU:</label
                      ><span class="text-danger ml-1">*</span>
                      <div class="text-secondary">
                        * Ditulis dalam format ribuan (X 1000). Contoh :
                        1.250.000 menjadi 1.250
                      </div>
                      <input-primary
                        id="nominalPks"
                        v-model="form.nominalPks"
                        required
                        type="number"
                        max-length="30"
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="caraPembayaran">Cara Pembayaran:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="caraPembayaran"
                        v-model="form.caraPembayaran"
                        required
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-4">
                  <!-- Lokasi Dokumen -->
                  <b-col>
                    <h4 class="mb-2">LOKASI DOKUMEN</h4>
                    <b-form-group>
                      <label for="lokasiDokumenFisik"
                        >Lokasi Dokumen Fisik:</label
                      ><span class="text-danger ml-1">*</span>
                      <input-primary
                        id="lokasiDokumenFisik"
                        v-model="form.lokasiDokumenFisik"
                        required
                      />
                    </b-form-group>
                    <b-form-group>
                      <label for="lokasiDokumenSoft">Lokasi Dokumen Soft:</label
                      ><span class="text-danger ml-1">*</span>
                      <b-form-file
                        accept=".pdf"
                        placeholder="Pilih Dokumen"
                        id="lokasiDokumenSoft"
                        ref="refLokasiDokumenSoft"
                        v-model="form.lokasiDokumenSoft"
                        @input="inputUploadDokumen"
                        required
                      ></b-form-file>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row class="mt-5">
                  <!-- MoU Mitra -->
                  <b-col>
                    <h4 class="mb-2 d-inline-block">
                      MoU MITRA
                      <b-button
                        id="idKsMitra"
                        class="ml-4"
                        variant="primary"
                        v-b-modal.modal-create
                        >Tambah Mitra<i class="ri-add-line ml-2"></i
                      ></b-button>
                    </h4>
                    <b-form-group
                      v-for="items in listInputMitra"
                      :key="items.idMitra"
                    >
                      <label for="namaMitra">Mitra:</label
                      ><span class="text-danger ml-1">*</span>
                      <b-row>
                        <b-col lg="9">
                          <input-primary
                            id="namaMitra"
                            v-model="items.namaMitra"
                            required
                            isDetail
                          />
                        </b-col>
                        <b-col class="align-self-center" lg="1">
                          <b-button
                            class="btn-block"
                            variant="success"
                            @click="showEditMitra(items.idMitra)"
                            ><i class="ri-edit-line ml-2"></i
                          ></b-button>
                        </b-col>
                        <b-col class="align-self-center" lg="1">
                          <b-button
                            class="btn-block"
                            variant="primary"
                            @click="showDetailMitra(items.idMitra)"
                            ><i class="ri-search-line ml-2"></i
                          ></b-button>
                        </b-col>
                        <b-col class="align-self-center" lg="1">
                          <b-button
                            class="btn-block"
                            variant="danger"
                            @click="delMitra(items.idMitra)"
                            ><i class="ri-delete-bin-line ml-2"></i
                          ></b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Validasi Form -->
                <b-row class="mt-5">
                  <b-col>
                    <transition name="hint" appear>
                      <div class="text-justify iq-bg-primary text-dark p-4">
                        <div class="w-full flex flex-wrap">
                          <!-- Status lengkap form -->
                          <div>
                            <h5 class="font-weight-bold">
                              Data Wajib Yang Belum Terisi:
                            </h5>
                            <div v-if="formValidation.errors.length > 0">
                              <div
                                v-for="error in formValidation.errors"
                                :key="error.content"
                              >
                                <div class="p-1">
                                  <span
                                    class="
                                      cursor-pointer
                                      font-weight-bold
                                      text-primary
                                    "
                                    @click="inputFocus(error.model)"
                                    >• {{ error.content }}</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div v-if="!formValidation.errors.length > 0">
                              <div class="text-success">Form Sudah Lengkap</div>
                            </div>
                          </div>
                        </div>
                        <div class="mt-4 text-primary">
                          * Cek kembali pengisian form jika tidak dapat
                          menyimpan data
                        </div>
                        <div class="mt-2 pl-2 text-danger">
                          <collapse-transition>
                            <div v-show="isError.berlakuPks.validBerlakuPks">
                              Tanggal Berlaku MoU tidak bisa kurang dari Tanggal
                              MoU.
                            </div>
                          </collapse-transition>
                          <collapse-transition>
                            <div
                              v-show="
                                isError.berakhirPks
                                  .validBerakhirPksFromTanggalKs
                              "
                            >
                              Tanggal Berakhir MoU tidak bisa kurang dari
                              Tanggal MoU.
                            </div>
                          </collapse-transition>
                          <collapse-transition>
                            <div
                              v-show="
                                isError.berakhirPks
                                  .validBerakhirPksFromBerlakuKs
                              "
                            >
                              Tanggal Berakhir MoU tidak bisa kurang dari
                              Tanggal Berlaku MoU.
                            </div>
                          </collapse-transition>
                          <collapse-transition>
                            <div v-show="isError.emailUt.validEmail">
                              Email harus mengandung karakter @
                            </div>
                          </collapse-transition>
                        </div>
                      </div>
                    </transition>
                  </b-col>
                </b-row>
                <!-- Button -->
                <b-row class="mt-5">
                  <b-col>
                    <router-link
                      class="btn btn-secondary dark-btn-primary btn-block"
                      :to="{ name: 'kerjasama.jenisDokumen' }"
                      role="button"
                      ><i class="ri-arrow-left-line"></i>Kembali</router-link
                    >
                  </b-col>
                  <b-col>
                    <b-button
                      class="btn-block"
                      variant="success"
                      type="submit"
                      @click="createData"
                      :disabled="!isValidForm"
                      ><i class="ri-save-line"></i>Simpan</b-button
                    >
                  </b-col>
                </b-row>
              </div>
              <!-- </b-form> -->
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- ./END:Table -->
    </b-row>
  </b-container>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { returnTrue } from '@/helpers'
// import moment from 'moment'

export default {
  name: 'kerjasama.mou.tambah',
  computed: {
    ...mapState('authService', ['token']),
    isValidForm() {
      const status =
        this.form.idJenisPks &&
        this.form.namaPks &&
        this.form.idUnitKs &&
        // this.form.idUnitPelaksana &&
        this.form.idKategoriPks &&
        this.form.idSubKategoriPks &&
        this.form.ruangLingkup &&
        this.form.satuanVol &&
        this.form.tglPks &&
        this.form.berlakuPks &&
        this.isValidBerlakuPks &&
        this.form.berakhirPks &&
        this.isValidBerakhirPks &&
        // this.form.jangkaWaktu &&
        this.form.nomorPksUt &&
        this.form.jabatanUt &&
        this.form.emailUt &&
        this.form.penghubungUt &&
        this.form.telpUt &&
        this.form.nominalPks &&
        this.form.caraPembayaran &&
        this.form.lokasiDokumenFisik &&
        this.form.lokasiDokumenSoft != null &&
        returnTrue(this.form.volume, [0, '0']) &&
        this.listInputMitra.length >= 1

      return status
    },
    isValidFormMitra() {
      const status =
        this.form.idMitra &&
        this.form.nomorPksMitra &&
        this.form.penandatanganMitra &&
        this.form.jabatanMitra != null
      return status
    },
    isValidModalMitra() {
      const status =
        this.modal.idMitra &&
        this.modal.nomorPksMitra &&
        this.modal.penandatanganMitra &&
        this.modal.jabatanMitra != null
      return status
    },
    isValidEmail() {
      const { validEmail } = this.isError.emailUt
      return !validEmail
    },
    isValidEmailMitra() {
      const { validEmailMitra } = this.isError.emailMitra
      return !validEmailMitra
    },
    isValidBerlakuPks() {
      const { validBerlakuPks } = this.isError.berlakuPks
      return !validBerlakuPks
    },
    isValidBerakhirPks() {
      const {
        validBerakhirPksFromTanggalKs,
        validBerakhirPksFromBerlakuKs
      } = this.isError.berakhirPks
      return !validBerakhirPksFromTanggalKs && !validBerakhirPksFromBerlakuKs
    },
    // Validasi Form
    formValidation() {
      const errors = []
      let i = 0
      const formRequired = [
        this.form.idJenisPks,
        this.form.namaPks,
        this.form.idUnitKs,
        // this.form.idUnitPelaksana,
        this.form.idKategoriPks,
        this.form.idSubKategoriPks,
        this.form.ruangLingkup,
        this.form.volume,
        this.form.satuanVol,
        this.form.tglPks,
        this.form.berlakuPks,
        this.form.berakhirPks,
        // this.form.jangkaWaktu,
        this.form.nomorPksUt,
        this.form.penandatanganUt,
        this.form.jabatanUt,
        this.form.emailUt,
        this.form.penghubungUt,
        this.form.telpUt,
        this.form.nominalPks,
        this.form.caraPembayaran,
        this.form.lokasiDokumenFisik,
        this.form.lokasiDokumenSoft,
        this.listInputMitra.length
      ]
      for (const condition of this.requiredForm) {
        if (['', null].includes(formRequired[i++])) {
          errors.push(condition)
        }
      }
      if (errors.length === 0) {
        return { valid: true, errors }
      } else {
        return { valid: false, errors }
      }
    }
  },
  mounted() {
    this.innitData()
  },
  methods: {
    returnTrue,
    /**
     * Focus input
     */
    inputFocus(data) {
      const refChar = 'ref' // ref = input datalist, !ref = input text
      if (['refTglPks'].includes(data)) {
        /** Flatpickr focus */
        this.$refs[data].$el.nextSibling.focus()
        this.$refs[data].$el._flatpickr.open()
      } else if (['refBerlakuPks'].includes(data)) {
        if (!this.form.tglPks) {
          this.$toast.info('Masukkan Tanggal MoU lebih dulu')
        } else {
          this.$refs[data].$el.nextSibling.focus()
          this.$refs[data].$el._flatpickr.open()
        }
      } else if (['refBerakhirPks'].includes(data)) {
        if (!this.form.tglPks) {
          this.$toast.info('Masukkan Tanggal MoU lebih dulu')
        } else if (!this.form.berlakuPks) {
          this.$toast.info('Masukkan Tanggal Berlaku MoU lebih dulu')
        } else {
          if (!this.isValidBerlakuPks) {
            this.$toast.info('Pastikan Tanggal Berlaku MoU sudah benar')
          } else {
            this.$refs[data].$el.nextSibling.focus()
            this.$refs[data].$el._flatpickr.open()
          }
        }
      } else if (['idKsMitra'].includes(data)) {
        /** Button open modal focus */
        window.scrollTo(0, 0)
        document.getElementById(data).click()
      } else if (['refUnitPelaksana'].includes(data)) {
        /** Relation dropdown focus */
        this.form.idUnitKs == null
          ? this.$toast.info('Pilih Unit Penyelenggara lebih dulu')
          : this.$refs[data].focus()
      } else if (['refSubKategoriPks'].includes(data)) {
        /** Relation dropdown focus */
        this.form.idKategoriPks == null
          ? this.$toast.info('Pilih Kode Kategori lebih dulu')
          : this.$refs[data].focus()
      } else if (data.includes(refChar)) {
        /** Dropdown focus */
        this.$refs[data].focus()
      } else {
        /** Input text focus */
        document.getElementById(data).focus()
      }
    },
    /**
     * Rules input email
     */
    inputEmail() {
      this.isError.emailUt = {
        validEmail: false
      }
      if (this.form.emailUt !== null) {
        if (!this.form.emailUt.includes('@')) {
          this.isError.emailUt.validEmail = true
        }
      }
    },
    inputEmailMitra() {
      this.isError.emailMitra = {
        validEmailMitra: false
      }
      if (this.form.emailMitra !== null) {
        if (!this.form.emailMitra.includes('@')) {
          this.isError.emailMitra.validEmailMitra = true
        }
      }
    },
    /**
     * Rules input tanggal
     */
    inputTanggalPks() {
      this.form.berlakuPks = null
      this.form.berakhirPks = null
    },
    inputTanggalBerlakuPks() {
      this.form.berakhirPks = null
      // -- Input Tanggal --
      // const berlakuPks = moment(this.form.berlakuPks).format('DD-MM-YYYY')
      const inputBerlakuPks = new Date(this.form.berlakuPks)
      // -- Tanggal PKS --
      // const tglPks = moment(this.form.tglPks).format('DD-MM-YYYY')
      const inputTglPks = new Date(this.form.tglPks)
      if (
        inputBerlakuPks.setHours(0, 0, 0, 0) < inputTglPks.setHours(0, 0, 0, 0)
      ) {
        this.isError.berlakuPks.validBerlakuPks = true
      } else this.isError.berlakuPks.validBerlakuPks = false
    },
    inputTanggalBerakhirPks() {
      // -- Input Tanggal --
      // const berakhirPks = moment(this.form.berakhirPks).format('DD-MM-YYYY')
      const inputBerakhirPks = new Date(this.form.berakhirPks)
      // -- Tanggal Berlaku PKS --
      // const berlakuPks = moment(this.form.berlakuPks).format('DD-MM-YYYY')
      const inputBerlakuPks = new Date(this.form.berlakuPks)
      // -- Tanggal PKS --
      // const tglPks = moment(this.form.tglPks).format('DD-MM-YYYY')
      const inputTglPks = new Date(this.form.tglPks)
      if (
        inputBerakhirPks.setHours(0, 0, 0, 0) < inputTglPks.setHours(0, 0, 0, 0)
      ) {
        this.isError.berakhirPks.validBerakhirPksFromTanggalKs = true
        this.isError.berakhirPks.validBerakhirPksFromBerlakuKs = true
      } else if (
        inputBerakhirPks.setHours(0, 0, 0, 0) <
        inputBerlakuPks.setHours(0, 0, 0, 0)
      ) {
        this.isError.berakhirPks.validBerakhirPksFromTanggalKs = false
        this.isError.berakhirPks.validBerakhirPksFromBerlakuKs = true
      } else {
        this.isError.berakhirPks.validBerakhirPksFromTanggalKs = false
        this.isError.berakhirPks.validBerakhirPksFromBerlakuKs = false
      }
    },
    /**
     * GET => List data Jenis MoU
     */
    async getListDataJenisPks_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/dropdownTransaksiPks?type=jenisPksMou`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataJenisPks = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * GET => List data Unit MoU
     */
    async getListDataUnitPks_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/dropdownTransaksiPks?type=listUnit`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataUnitPks = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * GET => List data Kategori MoU
     */
    async getListDataKategoriPks_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/dropdownTransaksiPks?type=kategoriPksMou`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataKategoriPks = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * GET => List data Sub Kategori MoU
     */
    async getListDataSubKategoriPks_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/dropdownTransaksiPks?type=subKategoriPks&idKategori=${this.form.idKategoriPks}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataSubKategoriPks = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * GET => List data Mitra
     */
    async getListDataMitra_() {
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/getListMasterPks?type=mitra`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.listDataMitra = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * GET => => List data Penghubung Mitra
     */
    async getListDataPenghubungMitra_() {
      if (this.form.idMitra) {
        this.idMitra = this.form.idMitra
      } else {
        this.idMitra = this.modal.idMitra
      }
      const result = await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/dropdownTransaksiPks?type=penghubungMitra&idMitra=${this.idMitra}`,
        method: 'GET',
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          if (this.form.idPenghubungMitra) this.form.idPenghubungMitra = null
          this.listDataPenghubungMitra = res.data
        })
        .catch(err => {
          console.log('ERROR', err)
          throw new Error(err)
        })
      return result
    },
    /**
     * Reset Value
     */
    resetValueMitra() {
      this.form.idMitra = null
      this.form.nomorPksMitra = null
      this.form.penandatanganMitra = null
      this.form.jabatanMitra = null
      this.form.emailMitra = null
      this.form.idPenghubungMitra = null
      this.form.telpMitra = null
    },
    resetValueModalMitra() {
      this.modal.idMitra = null
      this.modal.nomorPksMitra = null
      this.modal.penandatanganMitra = null
      this.modal.jabatanMitra = null
      this.modal.emailMitra = null
      this.modal.idPenghubungMitra = null
      this.modal.telpMitra = null
    },
    /**
     * INPUT => Kode Kategori
     */
    inputKodeKategori() {
      this.form.idSubKategoriPks = null
      if (this.form.idKategoriPks) this.getListDataSubKategoriPks_()
    },
    /**
     * INPUT => Mitra
     */
    inputKodeMitra() {
      this.getListDataPenghubungMitra_()
      if (this.form.idMitra) {
        // form
        this.getMitra = this.listDataMitra.find(dm => {
          return this.form.idMitra === dm.idMitra
        })
        this.form.emailMitra = this.getMitra.email
        this.form.telpMitra = this.getMitra.telp
      } else {
        // modal
        this.getMitraModal = this.listDataMitra.find(dm => {
          return this.modal.idMitra === dm.idMitra
        })
        this.modal.emailMitra = this.getMitraModal?.email
        this.modal.telpMitra = this.getMitraModal?.telp
      }
    },
    /**
     * INPUT => Unit
     */
    inputUnit() {
      if (this.form.idUnitPelaksana) this.form.idUnitPelaksana = null
    },
    inputUnitPelaksana() {
      if (this.form.idUnitPelaksana.includes(this.form.idUnitKs)) {
        this.form.idUnitPelaksana = this.form.idUnitPelaksana.filter(
          a => a !== this.form.idUnitKs
        )
        this.$toast.error(
          'Tidak dapat memilih unit yang sama dengan unit pelaksana'
        )
      }
    },
    /**
     * SHOW => Detail Mitra
     */
    showDetailMitra(idMitra) {
      this.listInputMitra.map(m => {
        if (idMitra === m.idMitra) {
          this.modal.idMitra = m.idMitra
          this.modal.kodeMitra = m.kodeMitra
          this.modal.namaMitra = m.namaMitra
          this.modal.nomorPksMitra = m.nomorPksMitra
          this.modal.penandatanganMitra = m.penandatanganMitra
          this.modal.jabatanMitra = m.jabatanMitra
          this.modal.emailMitra = m.emailMitra
          this.modal.idPenghubungMitra = m.idPenghubungMitra
          this.modal.telpMitra = m.telpMitra
          this.getListDataPenghubungMitra_()
        }
      })
      this.isModalDetailMitra = true
    },
    /**
     * CREATE => Mitra
     */
    addMitra() {
      this.getMitra = this.listDataMitra.find(dm => {
        return this.form.idMitra === dm.idMitra
      })
      const isAddedMitra = this.listInputMitra.find(dm => {
        return this.form.idMitra === dm.idMitra
      })
      if (isAddedMitra) {
        this.$toast.info('Mitra sudah pernah ditambahkan sebelumnya')
      } else {
        this.listInputMitra.push({
          idPksDetail: this.form.idPksDetail,
          idMitra: this.form.idMitra,
          kodeMitra: this.getMitra.kodeMitra,
          namaMitra: this.getMitra.namaMitra,
          nomorPksMitra: this.form.nomorPksMitra,
          penandatanganMitra: this.form.penandatanganMitra,
          jabatanMitra: this.form.jabatanMitra,
          emailMitra: this.form.emailMitra,
          idPenghubungMitra: this.form.idPenghubungMitra,
          telpMitra: this.form.telpMitra
        })
        this.resetValueMitra()
        this.isModalMitra = false
      }
    },
    /**
     * SHOW => Edit Mitra
     */
    showEditMitra(idMitra) {
      this.listInputMitra.map(m => {
        if (idMitra === m.idMitra) {
          this.modal.idMitra = m.idMitra
          this.modal.kodeMitra = m.kodeMitra
          this.modal.namaMitra = m.namaMitra
          this.modal.nomorPksMitra = m.nomorPksMitra
          this.modal.penandatanganMitra = m.penandatanganMitra
          this.modal.jabatanMitra = m.jabatanMitra
          this.modal.emailMitra = m.emailMitra
          this.modal.idPenghubungMitra = m.idPenghubungMitra
          this.modal.telpMitra = m.telpMitra
          this.getListDataPenghubungMitra_()
        }
      })
      this.isModalEditMitra = true
    },
    /**
     * EDIT => Mitra
     */
    addMitraModal() {
      const modalIndex = this.listInputMitra.findIndex(
        a => a.idMitra === this.modal.idMitra
      )
      this.listInputMitra[modalIndex].idPksDetail = this.modal.idPksDetail
      this.listInputMitra[modalIndex].idMitra = this.modal.idMitra
      this.listInputMitra[modalIndex].kodeMitra = this.modal.kodeMitra
      this.listInputMitra[modalIndex].namaMitra = this.modal.namaMitra
      this.listInputMitra[modalIndex].nomorPksMitra = this.modal.nomorPksMitra
      this.listInputMitra[
        modalIndex
      ].penandatanganMitra = this.modal.penandatanganMitra
      this.listInputMitra[modalIndex].jabatanMitra = this.modal.jabatanMitra
      this.listInputMitra[modalIndex].emailMitra = this.modal.emailMitra
      this.listInputMitra[
        modalIndex
      ].idPenghubungMitra = this.modal.idPenghubungMitra
      this.listInputMitra[modalIndex].telpMitra = this.modal.telpMitra
      this.resetValueModalMitra()
      this.isModalEditMitra = false
    },
    /**
     * DELETE => Mitra
     */
    delMitra(idMitra) {
      this.listInputMitra = this.listInputMitra.filter(
        data => data.idMitra !== idMitra
      )
    },
    /**
     * CLOSE => Modal
     */
    closeModalMitra() {
      this.resetValueMitra()
      this.isModalMitra = false
    },
    closeModalDetailMitra() {
      this.resetValueMitra()
      this.isModalDetailMitra = false
    },
    closeModalEditMitra() {
      this.resetValueMitra()
      this.isModalEditMitra = false
    },
    /**
     * INPUT => Upload Dokumen
     */
    async inputUploadDokumen() {
      if (this.form.lokasiDokumenSoft?.type !== 'application/pdf') {
        this.form.lokasiDokumenSoft = null
        this.$toast.error('Dokumen harus format PDF (.pdf)')
      }
    },
    /**
     * Create Data
     */
    async createData() {
      console.log('form: ', this.form)
      console.log('listInputMitra: ', this.listInputMitra)

      const bodyFormData = new FormData()
      bodyFormData.append('idJenisPks', this.form.idJenisPks)
      bodyFormData.append('namaPks', this.form.namaPks)
      bodyFormData.append('idUnitKsPenyelenggara', this.form.idUnitKs)
      this.form.idUnitPelaksana
        ? this.form.idUnitPelaksana.forEach(iu => {
            bodyFormData.append('idUnitKs[]', iu)
          })
        : bodyFormData.append('idUnitKs[]', '')
      // Kategori MoU
      bodyFormData.append('idKategoriPks', this.form.idKategoriPks)
      bodyFormData.append('idSubKategoriPks', this.form.idSubKategoriPks)
      bodyFormData.append('ruangLingkup', this.form.ruangLingkup)
      bodyFormData.append('volume', this.form.volume)
      bodyFormData.append('satuanVol', this.form.satuanVol)
      // Tanggal MoU
      bodyFormData.append('tglPks', this.form.tglPks)
      bodyFormData.append('berlakuPks', this.form.berlakuPks)
      bodyFormData.append('berakhirPks', this.form.berakhirPks)
      // Nominal MoU
      bodyFormData.append('nominalPks', this.form.nominalPks)
      bodyFormData.append('caraPembayaran', this.form.caraPembayaran)
      // MoU UT
      bodyFormData.append('nomorPksUt', this.form.nomorPksUt)
      bodyFormData.append('penandatanganUt', this.form.penandatanganUt)
      bodyFormData.append('jabatanUt', this.form.jabatanUt)
      bodyFormData.append(
        'emailUt',
        this.form.emailUt ? this.form.emailUt : null
      )
      bodyFormData.append(
        'penghubungUt',
        this.form.penghubungUt ? this.form.penghubungUt : null
      )
      bodyFormData.append('telpUt', this.form.telpUt ? this.form.telpUt : null)
      // Lokasi Dokumen
      bodyFormData.append('lokasiDokumenFisik', this.form.lokasiDokumenFisik)
      bodyFormData.append('lokasiDokumenSoft', this.form.lokasiDokumenSoft)
      // MoU Mitra
      this.listInputMitra.forEach(im => {
        bodyFormData.append(
          'idPksDetail[]',
          im.idPksDetail ? im.idPksDetail : null
        )
        bodyFormData.append('idMitra[]', im.idMitra)
        bodyFormData.append('nomorPksMitra[]', im.nomorPksMitra)
        bodyFormData.append('penandatanganMitra[]', im.penandatanganMitra)
        bodyFormData.append('jabatanMitra[]', im.jabatanMitra)
        bodyFormData.append(
          'idPenghubungMitra[]',
          im.idPenghubungMitra ? im.idPenghubungMitra : ''
        )
      })

      await axios({
        url: `${process.env.VUE_APP_URL_PUBLIC}/tambahTransaksiPks`,
        method: 'POST',
        data: bodyFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + this.token
        }
      })
        .then(res => {
          this.$router.push({ name: 'kerjasama.jenisDokumen' })
          this.$toast.success(res.data.message)
        })
        .catch(err => {
          console.log('ERROR', err)
          this.$toast.error(err.response.data.message)
          throw new Error(err)
        })
    },
    /**
     * Init Data
     */
    async innitData() {
      /** Get List data */
      await Promise.all([
        this.getListDataJenisPks_(),
        this.getListDataUnitPks_(),
        this.getListDataKategoriPks_(),
        this.getListDataMitra_()
      ])
    }
  },
  data() {
    return {
      // Required Form
      // requiredForm: [
      //   'Kode Jenis MoU',
      //   'Judul MoU',
      //   'Unit',
      //   'Kode Kategori',
      //   'Kode Sub Kategori',
      //   'Ruang Lingkup MoU',
      //   'Volume Luaran',
      //   'Satuan Luaran',
      //   'Tanggal MoU',
      //   'Tanggal Berlaku MoU',
      //   'Tanggal Berakhir MoU',
      //   // 'Jangka Waktu MoU',
      //   'Nomor MoU UT',
      //   'Pihak UT (Penandatangan)',
      //   'Jabatan UT',
      //   'Nominal MoU',
      //   'Realisasi MoU',
      //   'Cara Pembayaran',
      //   'Lokasi Dokumen Fisik',
      //   'Lokasi Dokumen Soft',
      //   'MoU Mitra'
      // ],
      /** Required Form -Plan C- */
      requiredForm: [
        { content: 'Kode Jenis MoU', model: 'refJenisPks' },
        { content: 'Judul MoU', model: 'namaPks' },
        { content: 'Unit Penyelenggara', model: 'refUnitKs' },
        // { content: 'Unit Pelaksana', model: 'refUnitPelaksana' },
        { content: 'Kode Kategori', model: 'refKategoriPks' },
        { content: 'Kode Sub Kategori', model: 'refSubKategoriPks' },
        { content: 'Ruang Lingkup MoU', model: 'ruangLingkup' },
        { content: 'Volume Luaran', model: 'volume' },
        { content: 'Satuan Luaran', model: 'satuanVol' },
        { content: 'Tanggal MoU', model: 'refTglPks' },
        { content: 'Tanggal Berlaku MoU', model: 'refBerlakuPks' },
        { content: 'Tanggal Berakhir MoU', model: 'refBerakhirPks' },
        { content: 'Nomor MoU UT', model: 'nomorPksUt' },
        { content: 'Pihak UT (Penandatangan)', model: 'penandatanganUt' },
        { content: 'Jabatan UT', model: 'jabatanUt' },
        { content: 'Email UT', model: 'emailUt' },
        { content: 'Penghubung UT', model: 'penghubungUt' },
        { content: 'Telepon UT', model: 'telpUt' },
        { content: 'Nominal MoU', model: 'nominalPks' },
        { content: 'Cara Pembayaran', model: 'caraPembayaran' },
        { content: 'Lokasi Dokumen Fisik', model: 'lokasiDokumenFisik' },
        { content: 'Lokasi Dokumen Soft', model: 'lokasiDokumenSoft' },
        { content: 'MoU Mitra', model: 'idKsMitra' }
      ],
      // isError
      isError: {
        emailUt: { validEmail: false },
        emailMitra: { validEmailMitra: false },
        berlakuPks: { validBerlakuPks: false },
        berakhirPks: {
          validBerakhirPksFromTanggalKs: false,
          validBerakhirPksFromBerlakuKs: false
        }
      },
      // Date Picker
      basicConfig: {
        altInput: true,
        altFormat: 'd-m-Y',
        dateFormat: 'Y-m-d'
      },
      // Mitra
      isModalMitra: false,
      isModalDetailMitra: false,
      isModalEditMitra: false,
      // kodeMitra: null,
      listInputMitra: [],
      // List Data
      listDataJenisPks: [],
      listDataUnitPks: [],
      listDataKategoriPks: [],
      listDataSubKategoriPks: [],
      listDataPenghubungMitra: [],
      listDataMitra: [],
      getMitra: [],
      // Form
      form: {
        idJenisPks: null,
        namaPks: null,
        idUnitKs: null,
        idUnitPelaksana: null,
        // Kategori MoU
        idKategoriPks: null,
        idSubKategoriPks: null,
        ruangLingkup: null,
        volume: null,
        satuanVol: null,
        // Tanggal MoU
        tglPks: null,
        berlakuPks: null,
        berakhirPks: null,
        // jangkaWaktu: null,
        // MoU UT
        nomorPksUt: null,
        penandatanganUt: null,
        jabatanUt: null,
        emailUt: null,
        penghubungUt: null,
        telpUt: null,
        // Nominal MoU
        nominalPks: null,
        caraPembayaran: null,
        // Lokasi Dokumen
        lokasiDokumenFisik: null,
        lokasiDokumenSoft: null,
        // MoU Mitra
        idMitra: null,
        kodeMitra: null,
        nomorPksMitra: null,
        penandatanganMitra: null,
        jabatanMitra: null,
        emailMitra: null,
        idPenghubungMitra: null,
        telpMitra: null
      },
      // Modal Mitra
      modal: {
        idMitra: null,
        kodeMitra: null,
        nomorPksMitra: null,
        penandatanganMitra: null,
        jabatanMitra: null,
        emailMitra: null,
        idPenghubungMitra: null,
        telpMitra: null
      }
    }
  }
}
</script>
